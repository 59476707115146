<script>
  import { onMount } from "svelte";
  import { googleAnalyticsID } from "config";
  import { page } from "@roxi/routify";
  import { checkOk, fetchJSON } from "../utils";
  import { loadingCount } from "../stores";

  import InitialLoad from "../components/InitialLoad.svelte";
  import PayPalScript from "../components/paypal/PayPalScript.svelte";
  import ServiceWorker from "../ServiceWorker.svelte";

  let countries, detectedCountryCode, symbols, rates;
  let pixelHtml = "";
  let host = "";
  if (process.env.config.environment == "development") {
    host = process.env.config.localServerUrl;
  }

  async function fetchPixelHtml() {
    try {
      const response = await fetch(host + "/api/v2/pixel-html");
      if (response.ok) {
        const data = await response.json();
        pixelHtml = data.content || "";
      }
    } catch (error) {
      console.error("Failed to fetch pixel HTML:", error);
    }
  }

  const loading = Promise.all([
    fetch(host + "/api/v1/countries")
      .then(checkOk)
      .then((response) => {
        detectedCountryCode =
          response.headers.get("X-Detected-Country-Code") || "GB";
        return response.json();
      })
      .then(({ data }) => {
        countries = data;
      }),
    fetchJSON("/api/v1/currency/symbols").then((data) => {
      symbols = data;
    }),
    fetchJSON("/api/v1/currency/rates").then((data) => {
      rates = data;
    }),
    fetchPixelHtml()
  ]);

  loadingCount.update((n) => n + 1);
  loading.finally(() => loadingCount.update((n) => n - 1));

  let ga;

  onMount(() => {
    // Update GTM each time the page changes
    ga = window.ga;
    if (ga && googleAnalyticsID) {
      ga("create", googleAnalyticsID, "auto");
    }
  });

  $: {
    const { path } = $page;
    if (ga) {
      ga("set", "page", path);
      ga("send", "pageview");
    }
  }
</script>

<svelte:head>
  <PayPalScript />
  <ServiceWorker />
</svelte:head>

<InitialLoad />

<slot scoped={{ loading, countries, symbols, rates, detectedCountryCode }} />

{#if pixelHtml}
  <div class="pixel-html">
    {@html pixelHtml}
  </div>
{/if}

<style global>
  @import "normalize.css";
  @import "../styles/icomoon.css";
  @import "../styles/global.css";

  .pixel-html {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
  }
</style>
